@font-face {
	font-family: 'Frutiger Next';
	src: url(assets/login/fonts/frutiger-next-light.woff) format('opentype');
	font-weight: 300;
}
@font-face {
	font-family: 'Frutiger Next';
	src: url(assets/login/fonts/frutiger-next-regular.woff) format('opentype');
	font-weight: 400;
}
@font-face {
	font-family: 'Frutiger Next';
	src: url(assets/login/fonts/frutiger-next-bold.woff) format('opentype');
	font-weight: 700;
}

:root {
	--gu: 8px;
	--zui-font-family: -apple-system, 'Frutiger Next', 'Helvetica Neue', 'Helvetica', 'Roboto',
		'Arial', sans-serif !important;

	--zui-h0-font-size: 58px !important;
	--zui-h0-line-height: 1.56521739 !important;
	--zui-h1-font-size: 36px !important;
	--zui-h1-line-height: 1.5483871 !important;
	--zui-h2-font-size: 36px !important;
	--zui-h2-line-height: 1.63636364 !important;
	--zui-h3-font-size: 28px !important;
	--zui-h3-line-height: 1.47368421 !important;
	--zui-h3-font-weight: 500 !important;
	--zui-h4-font-size: 22px !important;
	--zui-h4-line-height: 1.6 !important;
	--zui-subtitle1-font-size: 16px !important;
	--zui-subtitle1-line-height: 1.53846154 !important;
	--zui-subtitle1-font-weight: bold !important;
	--zui-subtitle2-font-size: 11px !important;
	--zui-subtitle2-line-height: 1.45454545 !important;
	--zui-body-font-size: 16px !important;
	--zui-body-line-height: 1.53846154 !important;
	--zui-caption-font-size: 14px !important;
	--zui-caption-line-height: 1.45454545 !important;
	--zui-label1-font-size: 16px !important;
	--zui-label1-line-height: 1.45454545 !important;
	--zui-label2-font-size: 12px !important;
	--zui-label2-line-height: 1.2 !important;
}

html,
body {
	margin: 0;
	height: 100%;
	width: 100%;
	font-family: var(--zui-font-family) !important;
	font: var(--zui-typography-body);
	font-synthesis: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.main {
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
}

.content {
	max-width: 400px;
	margin-top: 7vh;
	padding: var(--gu);
	padding-top: 0;
	width: 100%;
	height: 100%;
	max-height: 600px;
}

.margin-top-xs {
	margin-top: calc(var(--gu) * 2);
}

.margin-top-s {
	margin-top: calc(var(--gu) * 4);
}

.margin-top {
	margin-top: calc(var(--gu) * 8);
}

.margin-top-l {
	margin-top: calc(var(--gu) * 12);
}

a {
	color: var(--zui-color-sc-b-110);

	&:not(:hover) {
		text-decoration: none;
	}

	&:hover {
		color: var(--zui-color-info);
	}
}

zui-button {
	--zui-typography-subtitle1: var(--zui-typography-body);
}

h2 {
	font: var(--zui-typography-h3);
}
