@use '~@angular/material' as mat;

@include mat.core();

$vis-mat-primary: (
	500: var(--zui-color-gs-120),
	contrast: (
		500: var(--zui-color-gs-10),
	),
);

$vis-mat-accent: (
	500: var(--zui-color-info),
	contrast: (
		500: var(--zui-color-gs-10),
	),
);

$vis-mat-warn: (
	500: var(--zui-color-error),
	contrast: (
		500: var(--zui-color-gs-10),
	),
);

$vis-mat-secondary: (
	500: var(--zui-color-help),
	contrast: (
		500: var(--zui-color-gs-120),
	),
);

$dlpaccount-primary: mat.define-palette($vis-mat-primary, 500);
$dlpaccount-accent: mat.define-palette($vis-mat-accent, 500);
$dlpaccount-warn: mat.define-palette($vis-mat-warn, 500);
$dlpaccount-secondary: mat.define-palette($vis-mat-secondary, 500);
$dlpaccount-theme: mat.define-light-theme(
	(
		color: (
			primary: $dlpaccount-primary,
			accent: $dlpaccount-accent,
			warn: $dlpaccount-warn,
		),
	)
);

@include mat.all-component-themes($dlpaccount-theme);

$custom-typography: mat.define-typography-config(
	$font-family: '-apple-system, Frutiger Next, Helvetica Neue, Helvetica, Roboto, Arial, sans-serif',
);

@include mat.core($custom-typography);

.mat-select-disabled .mat-select-value,
.mat-form-field-appearance-legacy .mat-form-field-label {
	color: var(--zui-color-text-default);
}
